import React from "react";
import { Container, Button } from "shards-react"

const Camon = (props) => (
  <Container fluid className="main-content-container px-4 pb-4">
    <div className="error">
      <div className="error__content">
        {/* <h2>500</h2> */}
        <h4>Chúng tôi xin trân thành cảm ơn quý khách<br />đã tin tưởng và đăng ký dịch vụ của Tâm An.</h4>
        <p>Chúc quý khách một ngày mới bình an và vui vẻ.</p>
        <Button pill onClick={e => props.history.replace('/')}>&larr; Trở về</Button>
      </div>
    </div>
  </Container>
);

export default Camon;
