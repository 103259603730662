import React, { useState } from "react"
import QrScanner from 'qr-scanner'
import ReCAPTCHA from "react-google-recaptcha"
import { Modal, Spinner } from 'react-bootstrap'
import { Container, Row, Col, Card, CardHeader, CardBody, Form, FormInput, FormSelect, Button, FormRadio, FormCheckbox } from "shards-react"
import dateFormat from "dateformat"
import DatePicker from 'react-date-picker'
import classNames from "classnames"

import PageTitle from "../components/common/PageTitle"

import QrScannerWorkerPath from 'qr-scanner/qr-scanner-worker.min.js'
QrScanner.WORKER_PATH = QrScannerWorkerPath

const Dangky = (props) => {
  const recaptchaRef = React.createRef()
  const [show, setShow] = useState(false)
  const [dulieu, setDulieu] = useState({
    hoten: '',
    cmnd: '',
    ngaysinh: '',
    ngaysinh2: null,
    gioitinh: 'Nam',
    diachi: '',
    quoctich: '84',
    phone: ''
  })
  const [khaibao, setKhaibao] = useState({
    loai1: {
      value: 0,
      nuocluutru: '',
      nuocquacanh: '',
      tinhthanh: '',
      ngayve: ''
    },
    loai2: {
      value: 0,
      tungay: '',
      denngay: ''
    },
    loai3: {
      value: 0,
      ngaytiepxuc: ''
    },
    loai4: {
      value: 0,
      ngaytiepxuc: ''
    },
    loai5: {
      ho: false,
      sot: false,
      khotho: false,
      dauhong: false,
      khac: false,
      khong: true
    },
    loai6: {
      gan: false,
      than: false,
      phoi: false,
      timmach: false,
      caohuyetap: false,
      tieuduong: false,
      khac: false,
      khong: true
    }
  })
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone"
    }

    if (/android/i.test(userAgent)) {
      return "Android"
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS"
    }

    return "unknown"
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    const token = await recaptchaRef.current.executeAsync()
    if (!show) {
      if (!checkBirthYear()) {
        setShow(true)
        fetch(`${process.env.REACT_APP_API_URL}khaibao`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            thongtin: { ...dulieu, ngaysinh: dateFormat(dulieu.ngaysinh2, 'yyyy-mm-dd') },
            khaibao: khaibao,
            token: token
          }),
        }).then(response => response.json()).then(result => {
          setShow(false)
          if (result.id) {
            props.history.replace(`/cam-on`)
          } else {
            alert('Cảm ơn bạn đã quan tâm và đăng ký.')
            props.history.replace('/')
          }
        }).catch(err => {
          setShow(false)
        })
      } else {
        window.alert('Kiểm tra lại ngày sinh.')
      }
    }
  }
  const handleInputChange = (e) => {
    setDulieu({ ...dulieu, [e.currentTarget.name]: e.currentTarget.value })
  }
  const handleKhaibaoChange = (e) => {
    if (e.currentTarget.name !== undefined) {
      const tmp = khaibao[e.currentTarget.name]
      tmp.value = e.currentTarget.value
      setKhaibao({ ...khaibao, [e.currentTarget.name]: tmp })
    }
  }
  const handleKhaibaoChange2 = (e) => {
    if (e.currentTarget.name !== undefined) {
      const name = e.currentTarget.name.split('.')
      const tmp = khaibao[name[0]]
      if (e.currentTarget.type === 'checkbox') {
        tmp[name[1]] = e.currentTarget.checked
      } else {
        tmp[name[1]] = e.currentTarget.value
      }
      setKhaibao({ ...khaibao, [name[0]]: tmp })
    }
  }
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setShow(true)
      QrScanner.scanImage(URL.createObjectURL(event.target.files[0]))
        .then(result => {
          setShow(false)
          if (result) {
            const myArr = result.split("|")
            if (myArr.length >= 6) {
              const ngaysinh = myArr[3].slice(0, 2)
              const thangsinh = myArr[3].slice(2, 4)
              const namsinh = parseInt(myArr[3].slice(4))
              setDulieu({
                ...dulieu,
                hoten: myArr[2],
                cmnd: myArr[0],
                ngaysinh: `${namsinh}-${thangsinh}-${ngaysinh}`,
                ngaysinh2: new Date(`${namsinh}-${thangsinh}-${ngaysinh}`),
                gioitinh: myArr[4],
                diachi: myArr[5]
              })
            }
          }
        })
        .catch(error => { window.alert(JSON.stringify(error) || 'No QR code found.'); setShow(false) });
    }
  }
  const handleVerify = (value) => {
  }
  const handleInputChange3 = (e) => {
    setDulieu({ ...dulieu, ngaysinh2: e })
  }
  const checkBirthYear = () => {
    const hientai = dateFormat(dulieu.ngaysinh2, 'yyyy')
    return (((parseInt(dateFormat(new Date(), 'yyyy')) - parseInt(hientai)) > 100) || ((parseInt(dateFormat(new Date(), 'yyyy')) - parseInt(hientai)) < 0)) ? true : false;
  }
  return <React.Fragment>
    <Modal className="loadingModal" show={show} onHide={e => setShow(false)} backdrop="static" keyboard={false}>
      <Modal.Body>
        <Spinner animation="border" role="status" />
      </Modal.Body>
    </Modal>
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <PageTitle sm="12" title="Khai báo y tế" className="text-md-center" />
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Thông tin cá nhân</h6>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="3" className="form-group">
                    {getMobileOperatingSystem() === 'iOS' ? null : <label htmlFor="file" className="text-danger float-right">Quét mã QR</label>}
                    <label>Họ và tên</label>
                    <FormInput
                      required
                      placeholder="Họ và tên"
                      name="hoten"
                      value={dulieu.hoten}
                      onChange={handleInputChange}
                    />
                    <input type="file" id="file" style={{ position: 'absolute', left: -1000 }} onChange={onImageChange} />
                  </Col>
                  <Col md="3" className="form-group">
                    <label>CMND</label>
                    <FormInput
                      type="tel"
                      maxLength="12"
                      required
                      placeholder="CMND"
                      name="cmnd"
                      value={dulieu.cmnd}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="2" className="form-group">
                    <label>Năm sinh</label>
                    <DatePicker
                      required
                      onChange={handleInputChange3}
                      value={dulieu.ngaysinh2}
                      clearIcon={null}
                      className={classNames("form-control", checkBirthYear() ? 'is-invalid' : '')}
                      format="dd/MM/y"
                      dayPlaceholder="ngày"
                      monthPlaceholder="tháng"
                      yearPlaceholder="năm"
                    />
                  </Col>
                  <Col md="1" className="form-group">
                    <label>Giới tính</label>
                    <FormSelect
                      name="gioitinh"
                      value={dulieu.gioitinh}
                      onChange={handleInputChange}>
                      <option value="Nam">Nam</option>
                      <option value="Nữ">Nữ</option>
                      <option value="Khác">Khác</option>
                    </FormSelect>
                  </Col>
                  <Col md="3" className="form-group">
                    <label>Quốc tịch</label>
                    <FormSelect
                      name="quoctich"
                      value={dulieu.quoctich}
                      onChange={handleInputChange}>
                      <option value="84">Việt Nam</option>
                      <option value="0">Khác</option>
                    </FormSelect>
                  </Col>
                  <Col md="6" className="form-group">
                    <label>Địa chỉ</label>
                    <FormInput
                      required
                      placeholder="Địa chỉ"
                      name="diachi"
                      value={dulieu.diachi}
                      onChange={handleInputChange}
                    />
                  </Col>
                  <Col md="3" className="form-group">
                    <label>Điện thoại</label>
                    <FormInput
                      maxLength="10"
                      required
                      type="tel"
                      placeholder="Điện thoại"
                      name="phone"
                      value={dulieu.phone}
                      onChange={handleInputChange}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card small className="mb-3">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Khai báo y tế</h6>
                <p className="m-0">Trong vòng 14 ngày qua</p>
              </CardHeader>
              <CardBody>
                <Row form>
                  <Col md="12">
                    <label><strong>1. Có về từ nước ngoài</strong></label>
                    <fieldset className="d-flex">
                      <FormRadio name="loai1" value={1} onChange={handleKhaibaoChange}>Có</FormRadio>
                      <span className="ml-5" />
                      <FormRadio name="loai1" value={0} onChange={handleKhaibaoChange} defaultChecked>Không</FormRadio>
                    </fieldset>
                  </Col>
                </Row>
                {khaibao.loai1.value == 1 ? <Row form>
                  <Col md="3" className="form-group">
                    <label>Nước đã lưu trú</label>
                    <FormInput
                      placeholder="Nước đã lưu trú"
                      name="loai1.nuocluutru"
                      value={khaibao.loai1.nuocluutru}
                      onChange={handleKhaibaoChange2}
                    />
                  </Col>
                  <Col md="3" className="form-group">
                    <label>Nước quá cảnh</label>
                    <FormInput
                      placeholder="Nước quá cảnh"
                      name="loai1.nuocquacanh"
                      value={khaibao.loai1.nuocquacanh}
                      onChange={handleKhaibaoChange2}
                    />
                  </Col>
                  <Col md="3" className="form-group">
                    <label>Thành phố / Tỉnh</label>
                    <FormInput
                      placeholder="Thành phố / Tỉnh"
                      name="loai1.tinhthanh"
                      value={khaibao.loai1.tinhthanh}
                      onChange={handleKhaibaoChange2}
                    />
                  </Col>
                  <Col md="3" className="form-group">
                    <label>Ngày về Việt Nam</label>
                    <FormInput
                      type="date"
                      placeholder="Ngày về Việt Nam"
                      name="loai1.ngayve"
                      value={khaibao.loai1.ngayve}
                      onChange={handleKhaibaoChange2}
                    />
                  </Col>
                </Row> : null}
                <Row form>
                  <Col md="12">
                    <label><strong>2. Có sống hoặc đã đến nơi có dịch lưu hành</strong></label>
                    <fieldset className="d-flex">
                      <FormRadio name="loai2" value={1} onChange={handleKhaibaoChange}>Có</FormRadio>
                      <span className="ml-5" />
                      <FormRadio name="loai2" value={0} onChange={handleKhaibaoChange} defaultChecked>Không</FormRadio>
                    </fieldset>
                  </Col>
                </Row>
                {khaibao.loai2.value == 1 ? <Row form>
                  <Col md="2" className="form-group">
                    <label>Từ ngày</label>
                    <FormInput
                      placeholder="Từ ngày"
                      type="date"
                      name="loai2.tungay"
                      value={khaibao.loai2.tungay}
                      onChange={handleKhaibaoChange2}
                    />
                  </Col>
                  <Col md="2" className="form-group">
                    <label>Đến ngày</label>
                    <FormInput
                      placeholder="Đến ngày"
                      type="date"
                      name="loai2.denngay"
                      value={khaibao.loai2.denngay}
                      onChange={handleKhaibaoChange2}
                    />
                  </Col>
                </Row> : null}
                <Row form>
                  <Col md="12">
                    <label><strong>3. Có tiếp xúc với người được xác định hoặc nghi ngờ nhiễm COVID-19</strong></label>
                    <fieldset className="d-flex">
                      <FormRadio name="loai3" value={1} onChange={handleKhaibaoChange}>Có</FormRadio>
                      <span className="ml-5" />
                      <FormRadio name="loai3" value={0} onChange={handleKhaibaoChange} defaultChecked>Không</FormRadio>
                    </fieldset>
                  </Col>
                </Row>
                {khaibao.loai3.value == 1 ? <Row form>
                  <Col md="2" className="form-group">
                    <label>Ngày tiếp xúc</label>
                    <FormInput
                      placeholder="Ngày tiếp xúc"
                      type="date"
                      name="loai3.ngaytiepxuc"
                      value={khaibao.loai3.ngaytiepxuc}
                      onChange={handleKhaibaoChange2}
                    />
                  </Col>
                </Row> : null}
                <Row form>
                  <Col md="12">
                    <label><strong>4. Có tiếp xúc với người từ nước ngoài về (người Việt Nam hoặc người nước ngoài)</strong></label>
                    <fieldset className="d-flex">
                      <FormRadio name="loai4" value={1} onChange={handleKhaibaoChange}>Có</FormRadio>
                      <span className="ml-5" />
                      <FormRadio name="loai4" value={0} onChange={handleKhaibaoChange} defaultChecked>Không</FormRadio>
                    </fieldset>
                  </Col>
                </Row>
                {khaibao.loai4.value == 1 ? <Row form>
                  <Col md="2" className="form-group">
                    <label>Ngày tiếp xúc</label>
                    <FormInput
                      placeholder="Ngày tiếp xúc"
                      type="date"
                      name="loai4.ngaytiepxuc"
                      value={khaibao.loai4.ngaytiepxuc}
                      onChange={handleKhaibaoChange2}
                    />
                  </Col>
                </Row> : null}
                <Row form>
                  <Col md="3">
                    <label><strong>5. Có triệu chứng</strong></label>
                  </Col>
                  <Col md="3">
                    <fieldset>
                      <FormCheckbox name="loai5.ho" defaultChecked={khaibao.loai5.ho} onChange={handleKhaibaoChange2}>Ho</FormCheckbox>
                      <FormCheckbox name="loai5.sot" defaultChecked={khaibao.loai5.sot} onChange={handleKhaibaoChange2}>Sốt</FormCheckbox>
                      <FormCheckbox name="loai5.khotho" defaultChecked={khaibao.loai5.khotho} onChange={handleKhaibaoChange2}>Khó thở</FormCheckbox>
                    </fieldset>
                  </Col>
                  <Col md="3" className="form-group">
                    <fieldset>
                      <FormCheckbox name="loai5.dauhong" defaultChecked={khaibao.loai5.dauhong} onChange={handleKhaibaoChange2}>Đau họng</FormCheckbox>
                      <FormCheckbox name="loai5.khac" defaultChecked={khaibao.loai5.khac} onChange={handleKhaibaoChange2}>Khác</FormCheckbox>
                      <FormCheckbox name="loai5.khong" defaultChecked={khaibao.loai5.khong} onChange={handleKhaibaoChange2}>Không có triệu chứng</FormCheckbox>
                    </fieldset>
                  </Col>
                </Row>
                <Row form>
                  <Col md="3">
                    <label><strong>6. Có bệnh mạn tính</strong></label>
                  </Col>
                  <Col md="3">
                    <fieldset>
                      <FormCheckbox name="loai6.gan" defaultChecked={khaibao.loai6.gan} onChange={handleKhaibaoChange2}>Gan</FormCheckbox>
                      <FormCheckbox name="loai6.than" defaultChecked={khaibao.loai6.than} onChange={handleKhaibaoChange2}>Thận</FormCheckbox>
                      <FormCheckbox name="loai6.phoi" defaultChecked={khaibao.loai6.phoi} onChange={handleKhaibaoChange2}>Phổi</FormCheckbox>
                      <FormCheckbox name="loai6.timmach" defaultChecked={khaibao.loai6.timmach} onChange={handleKhaibaoChange2}>Tim mạch</FormCheckbox>
                    </fieldset>
                  </Col>
                  <Col md="3" className="form-group">
                    <fieldset>
                      <FormCheckbox name="loai6.caohuyetap" defaultChecked={khaibao.loai6.caohuyetap} onChange={handleKhaibaoChange2}>Cao huyết áp</FormCheckbox>
                      <FormCheckbox name="loai6.tieuduong" defaultChecked={khaibao.loai6.tieuduong} onChange={handleKhaibaoChange2}>Tiểu đường</FormCheckbox>
                      <FormCheckbox name="loai6.khac" defaultChecked={khaibao.loai6.khac} onChange={handleKhaibaoChange2}>Khác</FormCheckbox>
                      <FormCheckbox name="loai6.khong" defaultChecked={khaibao.loai6.khong} onChange={handleKhaibaoChange2}>Không có bệnh mạn tính</FormCheckbox>
                    </fieldset>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA}
                      onChange={handleVerify}
                      ref={recaptchaRef}
                      size="invisible"
                      hl="vi"
                      badge="inline"
                      className="d-flex justify-content-center mb-3"
                    />
                    <label className="mb-0"><input type="checkbox" required className="mr-1" /> Tôi cam kết thông tin trên là đúng sự thật, tôi hiểu rằng nếu cung cấp thông tin sai có thể dẫn đến hậu quả nghiêm trọng. Tôi sẽ chịu trách nhiệm hoàn toàn về thông tin đã cung cấp.</label>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Button type="submit" theme="primary" block size="lg">Khai báo</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
};

export default Dangky;
